import image1 from "../../assests/t-image1.c16c2c261034cf78ee57.png";
import image2 from '../../assests/t-image2.f5d2e52e514c544dfffb.jpg'
import image3 from '../../assests/t-image3.3894413f7bf2b4f1fc54.jpg'


export const testimonialsData =[
    {
        image: image1,
        review: "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
        name: "MATHEW HENDRICKSON ",
        status: "ENTREPRENEUR"
    },
    {
        image: image2,
        review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint",
        name: "JOHN KEVIN",
        status: "COACH"
    },
    {
        image: image3,
        review: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem",
        name: "FRANKLIN",
        status: "CUSTOMER"
    }
]
