import React from "react";
import "./Hero.css";
import Header from "../header/Header";
import heartrateimg from "../../images/download (7).png";
import heroimg from "../../images/hero_image.e19ac0c6b1009c7280f5.png";
import backheroimg from "../../images/hero_image_back.9f54bfba33048f4ca688.png";
import caloriesimg from "../../images/download (8).png";
import { motion} from "framer-motion";
import CountUp from 'react-countup';
export default function Hero() {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* best add */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "165px": "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>the best fitness club in the town</span>
        </div>

        {/* hero text */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div className="item-tex">
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>

        {/* figures */}
        <div className="figures">
          <div>
            <span>+ <CountUp end={140} start={100}/></span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>+ <CountUp end={978} start={888}/></span>
            <span>Members joined</span>
          </div>
          <div>
            <span>+ <CountUp end={50} start={5}/></span>
            <span>fitness programs</span>
          </div>
        </div>

        {/* hero button  */}
        <div className="hero-button">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div
          initial={{ right: "1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={heartrateimg} alt="heartrateimg" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={heroimg} alt="heroimg" className="heroimg" />
        <motion.img 
         initial={{right:"11rem"}}
        whileInView={{right:"20rem"}}
        transition={transition}
        src={backheroimg} alt="backheroimg" className="backheromg" />
        {/* Caleries */}
        <motion.div
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
          className="calories"
        >
          <img src={caloriesimg} alt="caloriesimg" className="caloriesimg" />
          <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
