import React from "react";
import "./Program.css";
import JoinButtonimg from '../../images/download (15).png'
export default function Program() {
  return (
    <div className="programs" id="programs">
      {/* header  */}
      <div className="programs-header">
        <span className="stroke-text">Explore our</span>
        <span>Programs</span>
        <span className="stroke-text">To shape you</span>
      </div>

      <div className="programs-categories">
        <div className="category">
            <span>
            Strength Training
            </span>
            <span>
            In this program, you are trained to improve your strength through many exercises.
            </span>
            <div className="join-now">
              <span>Join Now</span> <img src={JoinButtonimg} alt="joinnow" />
            </div>
        </div>

        <div className="category">
            <span>
            Cardio Training
            </span>
            <span>
            In this program, you are trained to do sequential moves in range of 20 until 30 minutes.
            </span>
            <div className="join-now">
              <span>Join Now</span> <img src={JoinButtonimg} alt="joinnow" />
            </div>
        </div>

        <div className="category">
            <span>
            Fat Burning
            </span>
            <span>
            This program is suitable for you who wants to get rid of your fat and lose their weight.
            </span>
            <div className="join-now">
              <span>Join Now</span> <img src={JoinButtonimg} alt="joinnow" />
            </div>
        </div>

        <div className="category">
            <span>
            Health Fitness
            </span>
            <span>
            This programs is designed for those who exercises only for their body fitness not body building.
            </span>
            <div className="join-now">
              <span>Join Now</span> <img src={JoinButtonimg} alt="joinnow" />
            </div>
        </div>
      </div>
    </div>
  );
}
