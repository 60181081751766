import React from "react";
import "./Reasons.css";
import reasonsimg1 from "../../images/image1.1091417d32e491f0bbb6.png";
import reasonsimg2 from "../../images/image4.2c0d30b9ce1579eb1109.png";
import reasonsimg3 from "../../images/image2.82da8b2725df896d8a8e.png";
import reasonsimg4 from "../../images/image3.69484cb0ee7687a8c28b.png";
import rightimg from "../../images/download (9).png";
import nbimg from "../../images/download (10).png";
import addas from "../../images/download (11).png";
import nikeimg from "../../images/download (12).png";
export default function Reasons() {
  return (
    <div className="reasons" id="reasons">
      <div className="left-reason">
        <img src={reasonsimg1} alt="reasonsimg1" />
        <img src={reasonsimg3} alt="reasonsimg2" />
        <img src={reasonsimg4} alt="reasonsimg3" />
        <img src={reasonsimg2} alt="reasonsimg4" />
      </div>
      <div className="right-reason">
        <span>some reasons</span>
        <div>
          <span className="stroke-text">Why</span>
          <span> choose us?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={rightimg} alt="tick" />
            <span>over 140+ expert coachs</span>
          </div>
          <div>
            <img src={rightimg} alt="tick" />
            <span>train smarter and faster than before</span>
          </div>
          <div>
            <img src={rightimg} alt="tick" />
            <span>1 free program for new member</span>
          </div>
          <div>
            <img src={rightimg} alt="tick" />
            <span>reliable partners</span>
          </div>
        </div>
        <span style={{ color: "gray", fontWeight: "normal" }}>
          OUR PARTNERS
        </span>
        <div className="partners">
          <img src={nbimg} alt="nbimg" />
          <img src={addas} alt="addasimg" />
          <img src={nikeimg} alt="nikeimg" />
        </div>
      </div>
    </div>
  );
}
