import React from "react";
import "./Footer.css";
import logo from "../../images/Logo.png";
import github from "../../images/download (1).png";
import insta from "../../images/download (13).png";
import linkdin from "../../images/download (14).png";
export default function Footer() {
  return (
    <div className="footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <img src={github} alt="github" />
          <img src={insta} alt="insta" />
          <img src={linkdin} alt="linkdin" />
        </div>
        <div className="logo-f">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
}
