import React from "react";
import "./Plans.css";
import tick from "../../images/download.png";
export default function Plans() {
  return (
    <div className="plans-container" id="plans">
    <div className="blur plans-blur-1"></div>
    <div className="blur plans-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">Ready to Start</span>
        <span>Your Journey</span>
        <span className="stroke-text">now withus</span>
      </div>
      {/* Plans Cards  */}
      <div className="plans">
        <div className="plan">
          <span>Basic Plan</span>
          <span>$ 25</span>
          <div className="features">
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>2 hours of excercises</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Free consultaion to coaches</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Access to The Community</span>
          </div>
          </div>
          <div>
            <span>See more benefits</span>
          </div>
          <button className="btn">Join now</button>
        </div>

        <div className="plan">
          <span>PREMIUM PLAN</span>
          <span>$ 30</span>
          <div className="features">
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>5 hours of excercises</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Free consultaion to coaches</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Accessto minibar</span>
          </div>
          </div>
          <div>
            <span>See more benefits</span>
          </div>
          <button className="btn">Join now</button>
        </div>

        <div className="plan">
          <span>Pro Plan</span>
          <span>$ 45</span>
          <div className="features">
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>8 hours of excercises</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Free consultaion to coaches</span>
          </div>
          <div className="feature">
            <img src={tick} alt="tick" />
            <span>Free Fitness Merchandises</span>
          </div>
          </div>
          <div>
            <span>See more benefits</span>
          </div>
          <button className="btn">Join now</button>
        </div>

      </div>
    </div>
  );
}
