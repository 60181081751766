import React, { useState } from "react";
import "./Header.css";
import Logo from "../../images/Logo.png";
import list from '../../images/list.png'
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuopen, setmenuopen] = useState(false)
  return (
    <div className="header" id="Home">
      <img src={Logo} alt="LogoPng" className="logo" />
        {menuopen === false && mobile === true ? (
      <div className="list-man" onClick={()=>setmenuopen(true)}>
       <img src={list} alt="list" />
      </div>
        ):(
    <ul className="header-manu">
        <li onClick={()=>setmenuopen(false)}>
          <a href="#Home">Home</a>
        </li>
        <li onClick={()=>setmenuopen(false)}>
          <a href="#programs">Programs</a>
        </li>
        <li onClick={()=>setmenuopen(false)}>
          <a href="#reasons">Why us</a>
        </li>
        <li onClick={()=>setmenuopen(false)}>
          <a href="#plans">Plans</a>
        </li>
        <li onClick={()=>setmenuopen(false)}>
          <a href="#testimonials">Testimonials</a>
        </li>
      </ul>
     )}
    </div>

    )}
export default Header;
